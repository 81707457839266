<template>
  <div>
    <h2 style="padding: 0.2rem 0 0.15rem; text-align: center; color: black">
      微信扫描下面的二维码分享
    </h2>
    <div style="text-align: center;margin:0.15rem 0;">
      <div
        class="qrcode"
        ref="qrCodeUrl"
      ></div>
    </div>
  </div>
</template>
<script>
import QRCode from "qrcodejs2";
export default {
  name: "qrcode",
  props: {
    msg: String,
  },
  methods: {
    creatQrCode() {
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: "https://arc.tencent.com/zh/index", // 需要转换为二维码的内容
        width: 200,
        height: 200,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
      console.log(qrcode);
    },
  },
  mounted() {
    this.creatQrCode();
  },
};
</script>

<style lang="less" scoped>
.qrcode {
  display: inline-block;
  img {
    width: 2rem;
    height: 2rem;
    background-color: #fff;
    padding: 0.06rem;
    box-sizing: border-box;
  }
}
</style>
